<template>
  <div style="display: flex">
    <el-container>
      <CommonTree ref="tree" node-key="id" :defaultProps="defaultProps" :isShowdig="false" :searchTitle="searchTitle"
        :treeTitle="$t('omit.Topicclassification')" :showCheckbox="false" :treeData="treeData" :treeExpand="true" style="font-size: 13px"
        @getNodeClick="handleNodeClick" @getTreeAdd="getTreeAdd" @getTreeDelete="getTreeDelete"
        @getTreeEdit="getTreeEdit" />
      <el-main>
        <head-layout :head-btn-options="headBtnOptions" :head-title="$t('cip.dc.dataobject.title.name')"
          @head-add="opDialog(4)">
        </head-layout>

        <grid-layout ref="gridLayout" :data-total="tableTotal" :gridRowBtn="gridRowBtn" :page="page"
          :tableData="tableData" :tableLoading="tableLoading" :tableOptions="tableOptions"
          @page-size-change="handleSizeChange" @page-current-change="handleCurrentChange" @page-refresh-change="onLoad"
          @gird-handle-select-click="selectionChange" @grid-see="SeeData" @grid-edit="rowEdit" @grid-romve="rowDel">
          <template slot="sourceType" slot-scope="{ row }">
            {{ gettype(row.sourceType) }}
          </template>
        </grid-layout>
      </el-main>
    </el-container>

    <!-- <el-container> -->

    <!-- </el-container> -->

    <basic-container>
     

      <!-- 删除目录弹框 和 删除数据对象 -->

      <el-dialog :visible.sync="dialogVisible2" title="提示">
        <div>确定要删除当前目录？</div>
        <div slot="footer" class="dialog-footer">
          <el-button style="margin: 0px 20px" @click="dialogVisible2 = false">{{
            $t("cancelText")
          }}</el-button>
          <el-button style="margin: 0px 20px" type="primary" @click="Mdeltree">{{ $t("submitText") }}</el-button>
        </div>
      </el-dialog>

      <el-dialog :title="$t('cip.dc.dataobject.title.tip')" :visible.sync="dialogVisible3">
        <div>{{ $t("cip.dc.dataobject.msg.delmsg3") }}</div>
        <div slot="footer" class="dialog-footer">
          <el-button style="margin: 0px 20px" @click="dialogVisible3 = false">{{
            $t("cancelText")
          }}</el-button>
          <el-button style="margin: 0px 20px" type="primary" @click="MDelObjectList">{{ $t("submitText") }}</el-button>
        </div>
      </el-dialog>



      <el-drawer :title="$t('cip.dc.dataobject.title.tip2')" :visible.sync="drawer" :with-header="false"
        @close="closedrawer" size="60%">
        <div v-if="seetype == 1">
          <div v-if="!isEs">
            <head-layout :head-btn-options="headBtnOptions2" :head-title="$t('cip.dc.dataobjDComission.searchtj')" @head-add2="addParms"
              @head-see="searchSee">
            </head-layout>
            <el-table :data="seeParms" border style="width: 100%; margin-top: 10px">
              <el-table-column :label="$t('cip.dc.dataobjDComission.filedname')">
                <template slot-scope="scope">
                  <el-select filterable clearable v-model="scope.row.fieldName" :placeholder="$t('cip.dc.DComission.selectPlaceholder')">
                    <el-option v-for="(item, index) in tableData4List" :key="index" :label="item.column_name"
                      :value="item.column_name">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column :label="$t('cip.dc.dataobjDComission.type')">
                <template slot-scope="scope">
                  <el-select filterable clearable v-model="scope.row.queryType" :placeholder="$t('cip.dc.DComission.selectPlaceholder')"
                    @change="changeQueryType2($event, scope.row)">
                    <el-option v-for="(item, index) in queryTypeList" :key="index" :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>

              <el-table-column :label="$t('cip.dc.dataobject.field.enterValue')" width="360">
                <template slot-scope="scope">
                  <div v-if="scope.row.queryType && scope.row.queryType !== '-1'">
                    <div v-if="scope.row.queryType == '3'">
                      <el-input clearable v-model="scope.row.startValue" :placeholder="$t('cip.dc.offlineDComission.star')" style="width: 50%"></el-input>
                      <el-input clearable v-model="scope.row.endValue" :placeholder="$t('cip.dc.offlineDComission.end')" style="width: 50%"></el-input>
                    </div>
                    <el-input v-if="scope.row.queryType !== '3'" v-model="scope.row.inputValue"
                      ></el-input>
                  </div>
                </template>
              </el-table-column>

              <el-table-column :label="$t('cip.dc.dataobjDComission.caozuo')" width="80">
                <template slot-scope="scope">
                  <el-button type="text" @click="delParms(scope.row)">{{ $t('cip.dc.dataobjDComission.del') }}</el-button>
                </template>
              </el-table-column>
            </el-table>

            <head-layout :head-title="$t('cip.dc.dataobjDComission.searchres')" :head-btn-options="EsOptions" @head-toEs="toEs"> </head-layout>
            <el-table :data="tableData4" style="width: 100%" class="show_table2" height="calc(100vh - 210px)">
              <el-table-column v-for="(item, index) in tableData4List" :key="index" :label="item.column_name"
                :prop="item.column_name" min-width="150">
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
              :currentPage="currentPage2" :page-sizes="[10, 50, 100]" :page-size="PageSize2"
              layout="total,sizes, prev, pager, next" :total="totalCount2" class="paging">
            </el-pagination>
          </div>

          <div v-else>
            <head-layout :head-btn-options="EsOptions2" head-title="生成条件" @head-back="toback" @head-create="createES(1)">
            </head-layout>

            <div style="padding: 10px;">
              <el-form :model="Esform" :rules="rules" ref="ruleForm" label-width="100px">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="图表类型" prop="Estype">
                      <el-select v-model="Esform.Estype" placeholder="请选择图表类型">
                        <el-option label="折线图" value="1"></el-option>
                        <el-option label="条形图" value="2"></el-option>
                        <el-option label="饼图" value="3"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="限制条数" prop="Esnum">
                      <el-input-number v-model="Esform.Esnum" :controls="false" :min="1" :max=PageSize2
                        style="width: 100%;"></el-input-number>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="12">
                    <el-form-item label="x轴" prop="Esx" v-if="Esform.Estype != 3">
                      <el-select v-model="Esform.Esx" placeholder="请选择x轴" clearable filterable>
                        <el-option v-for="item in EsXlist" :label="item.column_name"
                          :value="item.column_name"></el-option>

                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="12">
                    <el-form-item label="展示数据" prop="Esy">
                      <el-select v-model="Esform.Esy" placeholder="请选择展示数据" multiple clearable filterable>
                        <el-option v-for="item in EsYlist" :label="item.column_name"
                          :value="item.column_name"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>


                </el-row>


              </el-form>
            </div>

            <head-layout head-title="图表">
            </head-layout>


            <div style="margin-top: 30px">

              <div style="width: 100%">
                <div ref="BottomEchart" class="echart" :style="myChartStyle" id="BottomEchart"></div>
              </div>
            </div>


          </div>


        </div>

        <div v-if="seetype == 2">

          <div v-if="!isEs">
            <head-layout :head-title="$t('cip.dc.dataobjDComission.searchres')" :head-btn-options="EsOptions" @head-toEs="toEs"> </head-layout>
            <el-table :data="tableData4" style="width: 100%" class="show_table" height="calc(100vh - 150px)">
              <el-table-column v-for="(item, index) in tableData4List" :key="index" :label="item" :prop="item"
                min-width="150">
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
              :currentPage="currentPage2" :page-sizes="[10, 50, 100]" :page-size="PageSize2"
              layout="total,sizes, prev, pager, next" :total="totalCount2" class="paging">
            </el-pagination>
          </div>
          <div v-else>
            <head-layout :head-btn-options="EsOptions2" head-title="生成条件" @head-back="toback" @head-create="createES(2)">
            </head-layout>

            <div style="padding: 10px;">
              <el-form :model="Esform" :rules="rules" ref="ruleForm" label-width="100px">
                <el-row>
                  <el-col :span="12">
                    <el-form-item label="图表类型" prop="Estype">
                      <el-select v-model="Esform.Estype" placeholder="请选择图表类型">
                        <el-option label="折线图" value="1"></el-option>
                        <el-option label="条形图" value="2"></el-option>
                        <el-option label="饼图" value="3"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="12">
                    <el-form-item label="限制条数" prop="Esnum">
                      <el-input-number v-model="Esform.Esnum" :controls="false" :min="1" :max=PageSize2
                        style="width: 100%;"></el-input-number>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row>
                  <el-col :span="12">
                    <el-form-item label="x轴" prop="Esx" v-if="Esform.Estype != 3">
                      <el-select v-model="Esform.Esx" placeholder="请选择x轴" clearable filterable>
                        <el-option v-for="item in EsXlist" :label="item" :value="item"></el-option>

                      </el-select>
                    </el-form-item>
                  </el-col>

                  <el-col :span="12">
                    <el-form-item label="展示数据" prop="Esy">
                      <el-select v-model="Esform.Esy" placeholder="请选择展示数据" multiple clearable filterable>
                        <el-option v-for="item in EsYlist" :label="item" :value="item"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>


                </el-row>


              </el-form>
            </div>

            <head-layout head-title="图表">
            </head-layout>


            <div style="margin-top: 30px">

              <div style="width: 100%">
                <div ref="BottomEchart" class="echart" :style="myChartStyle" id="BottomEchart"></div>
              </div>
            </div>


          </div>

        </div>

        <div v-if="seetype == 3">
          <head-layout :head-btn-options="headBtnOptions2" :head-title="$t('cip.dc.dataobjDComission.searchtj')" @head-add2="addParms" @head-see="searchSee">
          </head-layout>
          <el-table :data="seeParms" border style="width: 100%; margin-top: 10px">
            <el-table-column :label="$t('cip.dc.dataobjDComission.filedname')">
              <template slot-scope="scope">
                <el-select filterable clearable v-model="scope.row.fieldName" :placeholder="$t('cip.dc.DComission.selectPlaceholder')">
                  <el-option v-for="(item, index) in tableData4List2" :key="index" :label="item" :value="item">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column :label="$t('cip.dc.dataobjDComission.type')">
              <template slot-scope="scope">
                <el-select filterable clearable v-model="scope.row.queryType" :placeholder="$t('cip.dc.DComission.selectPlaceholder')"
                  @change="changeQueryType2($event, scope.row)">
                  <el-option v-for="(item, index) in queryTypeList" :key="index" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>

            <el-table-column :label="$t('cip.dc.dataobject.field.enterValue')" width="360">
              <template slot-scope="scope">
                <div v-if="scope.row.queryType && scope.row.queryType !== '-1'">
                  <div v-if="scope.row.queryType == '3'">
                    <el-input clearable v-model="scope.row.startValue" :placeholder="$t('cip.dc.offlineDComission.star')" style="width: 50%"></el-input>
                    <el-input clearable v-model="scope.row.endValue" :placeholder="$t('cip.dc.offlineDComission.end')" style="width: 50%"></el-input>
                  </div>
                  <el-input v-if="scope.row.queryType !== '3'" v-model="scope.row.inputValue"
                    ></el-input>
                </div>
              </template>
            </el-table-column>

            <el-table-column :label="$t('cip.dc.dataobjDComission.caozuo')" width="80">
              <template slot-scope="scope">
                <el-button type="text" @click="delParms(scope.row)">{{ $t('cip.dc.dataobjDComission.del') }}</el-button>
              </template>
            </el-table-column>
          </el-table>

          <head-layout :head-title="$t('cip.dc.dataobjDComission.searchres')"> </head-layout>
          <el-table :data="tableData4" style="width: 100%" class="show_table2" height="calc(100vh - 210px)">
            <el-table-column v-for="(item, index) in tableData4List" :key="index" :label="item" :prop="item"
              min-width="150">
            </el-table-column>
          </el-table>
          <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
            :currentPage="currentPage2" :page-sizes="[10, 50, 100]" :page-size="PageSize2"
            layout="total,sizes, prev, pager, next" :total="totalCount2" class="paging">
          </el-pagination>
        </div>
      </el-drawer>
    </basic-container>
  </div>
</template>

<script>
import {
  AddObjectList,
  DelObjectList,
  DelObjTree,
  getColumnsList,
  getDataApisList,
  getDataApisXQ,
  getDataOBJXQ,
  getDataTree,
  getDTime,
  getSourcesList2,
  getSourcesTableList2,
  ObjectList,
  SaveAddTree,
  seeData,
  dataObjectSF,
} from "@/api/dataAcquisition/index";
import { getPowerconsumption3 } from "@/api/Ai";
import seeUrl from "@/api/Ai/seeurl";
import * as echarts from "echarts";

import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
import CommonTree from "@/views/components/com_tree/index";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";

export default {
  watch: {
    // filterText(val) {
    //   this.$refs.tree.filter(val);
    // }
  },
  components: {
    CommonTree,
    GridLayout,
    HeadLayout,
  },
  data() {
    return {
      rules: {

        Estype: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
        Esnum: [
          { required: true, message: '请填写数量', trigger: 'change' }
        ],
        Esx: [
          { required: true, message: '请选择X轴', trigger: 'change' }
        ],
        Esy: [
          { required: true, message: '请选择Y轴', trigger: 'change' }
        ],
      },
      myChartStyle: {
        float: "left",
        width: "100%",
        height: "500px",
        margin: "5px",
      }, // 图表样式
      isEs: false,
      EsOptions: [
        {
          label: "图表",
          emit: "head-toEs",
          type: "button",
          icon: "",
        },
      ],

      EsOptions2: [
        {
          label: this.$t("cip.cmn.btn.celBtn"),
          emit: "head-back",
          type: "button",
          icon: "",
          loading: false,
        },
        {
          label: "生成图表",
          emit: "head-create",
          type: "button",
          icon: "",

        },


      ],

      Esform: {
        Estype: "1",
        Esnum: "",
        Esx: "",
        Esy: "",
      },

      EsXlist: [],
      EsYlist: [],







      seeParms: [
        {
          fieldName: "", //字段名称
          queryType: "", //,
          inputValue: "", //输入值,
          startValue: "", //开始值,
          endValue: "", //结束值
        },
      ],
      headBtnOptions2: [
        {
          label: this.$t("cip.cmn.btn.addBtn"),
          emit: "head-add2",
          type: "button",
          icon: "",
        },
        {
          label: "查询",
          emit: "head-see",
          type: "button",
          icon: "",
        },
      ],
      totalCount2: 0,
      rowId: "",
      currentPage2: 1,
      PageSize2: 50,
      DtimeType: [],
      searchTitle: "name",
      DelLsitid: "",
      tableOptions: {
        customRowAddBtn: false,
        menuWidth: 300,
        column: [
          {
            label: this.$t("cip.dc.dataobject.field.code"),
            prop: "code",
            cell: true,
          },
          {
            label: this.$t("cip.dc.dataobject.field.identification"),
            prop: "label",
            cell: true,
          },
          {
            label: this.$t("cip.dc.dataobject.field.type"),
            prop: "sourceType",
            cell: true,
            slot: true,
          },
          {
            label: this.$t("cip.dc.dataobject.field.remark"),
            prop: "remark",
            cell: true,
          },
        ],
      },
      tableData4: [],
      seetype: "",
      tableData4List: [],
      tableData4List2: [],
      filterText: "",
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      AddTitle: "新建",
      addTreeData: {
        parentId: "0",
        name: "",
        type: "0",
      },
      ObjParms: {
        query: {
          current: 1,
          size: 20,
        },
        dataObjectTypeId: null,
      },

      ObjParms2: {
        query: {
          current: 1,
          size: 10,
        },
        modelId: null,
      },
      ObjTreeid: "", //删除树id
      TypeOp: [
        {
          label: "数据库",
          value: 1,
        },
        {
          label: "API接口",
          value: 2,
        },
      ],
      listAdd: {
        modelId: "",
        code: "",
        label: "",
        id: "", //主键ID 修改时传入
        remark: "", //备注
        sourceType: null, //类型 1-数据库 2-api
        dataObjectTypeId: "", //数据对象类型id
        //元数据详情
        metadataInfoDTO: {
          apiId: "", //api类型使用
          metadataSourceId: "", // 数据库id 数据库类型使用
          metadataTableId: "", //表id数据库类型使用
          saveFields: [],
          //  {
          //   //保存字段 api类型、数据库类型共用
          //   fieldId: "", //字段id数据库类型使用 对应id
          //   fieldName: "",//字段id数据库类型使用 对应id
          // },
          conditionFields: [],
          //  {
          //   //条件字段 api类型、数据库类型共用
          //   fieldId: "", //字段id 数据库类型使用 对应id
          //   fieldName: "",//字段名称api类型使用 对应reqParams.paramName
          //   defaultValue: "", //默认值 api类型、数据库类型共用 对应resqarams.defaultValue
          //   inputValue: "", //录入值api类型、数据库类型共用 用户输入
          // },
          compareFields: [],
          //  {
          //   //比对字段api类型、数据库类型共用
          //   fieldId: "",//数据库类型使用 对应id
          //   fieldName: "", //api类型使用 对应resParams.paramName
          // },

          sortFields: [],
          //排序字段
        },
      },
      queryTypeList: [
        { label: this.$t('cip.dc.offlineDComission.dengyu'), value: "1", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.budengyu'), value: "2", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.qujian'), value: "3", type: "1" },
        { label: this.$t('cip.dc.offlineDComission.dayu'), value: "4", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.xiaoyu'), value: "5", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.dadengyu'), value: "6", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.xiaodengyu'), value: "7", type: "0" },
        { label: this.$t('cip.dc.offlineDComission.baohan'), value: "8", type: "1" },
        { label: this.$t('cip.dc.offlineDComission.bubaohan'), value: "9", type: "1" },
      ],
      tableID: "",
      tableData: [],
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
      treeWay: "",
      treeWay2: "",

      SJKOP: [], //数据库下拉
      SBMOP: [], //数据库表名下拉
      APIOP: [], //api下拉
      SBZDbc: [], //表字段 保存
      SBZDtj: [], //表字段 条件
      SBZDdb: [], //表字段 对比
      SBZDpx: [], //排序字段
      CanEdit: false, //控制右侧操作
      tableTotal: 0,
      drawer: false, //预览
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      tableLoading: false,
      gridRowBtn: [
        {
          label: this.$t("cip.cmn.btn.editBtn"),
          emit: "grid-edit",
          remark: "edit",
          type: "text",
          icon: "",
        },
        {
          label: this.$t("cip.dc.dataobject.title.tip2"),
          emit: "grid-see",
          remark: "see",
          type: "text",
          icon: "",
        },

        {
          label: this.$t("cip.cmn.btn.delBtn"),
          emit: "grid-romve",
          remark: "remove",
          type: "text",
          icon: "",
        },
      ],
      headBtnOptions: [
        {
          label: this.$t("cip.cmn.btn.addBtn"),
          emit: "head-add",
          type: "button",
          remark: "",
          icon: "",
        },
      ],



      xAxis: {
        axisLabel: {
          interval: 0,
          rotate: 30,
        },
        data: [],
      },
      option: {
        tooltip: {},
        title: {
          show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
          text: "数据对象预览展示"
        },
        xAxis: {},
        yAxis: {},

        legend: {

          data: [

          ],

          // height: 30,
          // top: "0%",
          // bottom: "3%",
        },


      },


    };
  },
  mounted() {
    this.getdemotree();
  },

  methods: {

    toEs() {
      this.isEs = true
      this.EsXlist = this.tableData4List
      this.EsYlist = this.tableData4List
    },

    toback() {
      this.isEs = false
    },

    getEsList(e, num) {
      let aa = []
      if (num) {
        let list = JSON.parse(JSON.stringify(this.tableData4))


        list = list.slice(0, num)
        list.forEach(element => {
          aa.push(element[e])
        });
      } else {
        this.tableData4.forEach(element => {
          aa.push(element[e])
        });
      }

      return aa
    },

    getEsList2(e, num) {
      let aa = []
      if (num) {
        let list = JSON.parse(JSON.stringify(this.tableData4))
        list = list.slice(0, num)
        list.forEach(element => {
          aa.push(element[e])
        });
      } else {
        this.tableData4.forEach(element => {
          aa.push(element[e])
        });
      }

      return eval(aa.join("+"))
    },

    //创建折现图
    createES(type) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {

          let xAxis = {
            axisLabel: {
              interval: 0,
              rotate: 30,
            },
            data: [],
          };
          this.option.series = []

          if (type == 1) {
            if (this.Esform.Estype == 1) {
              this.Esform.Esy.forEach(element1 => {
                this.option.series.push({
                  type: "line", // 形状为折现
                  data: this.getEsList(element1, this.Esform.Esnum),
                  name: element1, // legend属性
                  symbolSize: 8,
                  label: {

                  },
                });

                this.option.legend.data.push({ name: element1 })
              });

            }
            if (this.Esform.Estype == 2) {
              this.Esform.Esy.forEach(element1 => {
                this.option.series.push({
                  type: "bar", // 形状为柱状
                  data: this.getEsList(element1, this.Esform.Esnum),
                  name: element1, // legend属性
                  symbolSize: 8,
                  label: {
                    // 柱状图上方文本标签，默认展示数值信息
                    show: true,
                    position: "top",
                  },
                });

                this.option.legend.data.push({ name: element1 })
              });
            }
            if (this.Esform.Estype == 3) {
              this.option.legend = {
              },
                this.option.series = [
                  {
                    name: '总数',
                    type: 'pie',
                    radius: '50%',
                    data: [
                    ],
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                    }
                  }
                ]

              this.option.xAxis = undefined
              this.option.yAxis = undefined
              this.Esform.Esy.forEach(element1 => {
                this.option.series[0].data.push({ name: element1, value: this.getEsList2(element1, this.Esform.Esnum) })
              });



            }
          }

          if (type == 2) {
            if (this.Esform.Estype == 1) {
              this.Esform.Esy.forEach(element1 => {
                this.option.series.push({
                  type: "line", // 形状为折现
                  data: this.getEsList(element1, this.Esform.Esnum),
                  name: element1, // legend属性
                  symbolSize: 8,
                  label: {

                  },
                });

                this.option.legend.data.push({ name: element1 })
              });

            }
            if (this.Esform.Estype == 2) {
              this.Esform.Esy.forEach(element1 => {
                this.option.series.push({
                  type: "bar", // 形状为柱状
                  data: this.getEsList(element1, this.Esform.Esnum),
                  name: element1, // legend属性
                  symbolSize: 8,
                  label: {
                    // 柱状图上方文本标签，默认展示数值信息
                    show: true,
                    position: "top",
                  },
                });

                this.option.legend.data.push({ name: element1 })
              });
            }


            if (this.Esform.Estype == 3) {
              this.option.legend = {
              },
                this.option.series = [
                  {
                    name: '总数',
                    type: 'pie',
                    radius: '50%',
                    data: [
                    ],
                    emphasis: {
                      itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                    }
                  }
                ]

              this.option.xAxis = undefined
              this.option.yAxis = undefined
              this.Esform.Esy.forEach(element1 => {
                this.option.series[0].data.push({ name: element1, value: this.getEsList2(element1, this.Esform.Esnum) })
              });



            }


          }
          if (this.Esform.Estype == 1 || this.Esform.Estype == 2) {
            xAxis.data = this.getEsList(this.Esform.Esx, this.Esform.Esnum)
            this.option.xAxis = xAxis
          }


          let myChart = echarts.init(document.getElementById("BottomEchart"));
          console.log(this.option, "llllllllll");
          myChart.dispose();
          let that = this
          this.$nextTick(() => {
            this.initEcharts("BottomEchart", this.option);
          });





        } else {
          console.log('error submit!!');
          return false;
        }
      });




    },

    initEcharts(id, option) {
      const myChart = echarts.init(document.getElementById(id));
      myChart.setOption(option);
      // 随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        myChart.resize();
      });
    },


    addParms() {
      this.seeParms.push({
        fieldName: "", //字段名称
        queryType: "", //,
        inputValue: "", //输入值,
        startValue: "", //开始值,
        endValue: "", //结束值
      });
    },

    delParms(row) {
      this.seeParms.forEach((element, index) => {
        if (element == row) {
          this.seeParms.splice(index, 1);
        }
      });
    },
    changeQueryType2(e, row) {
      row.inputValue = ""; //输入值,
      row.startValue = ""; //开始值,
      row.endValue = ""; //结束值
    },

    searchSee() {
      let parms = {
        id: this.rowId,
        current: this.currentPage2,
        size: this.PageSize2,
        metadataInfoDTO: {
          conditionFields: this.kseeParms(),
        },
      };
      this.MseeData(parms);
    },

    MseeData(parms) {
      if (this.seetype == 2) {
        getPowerconsumption3({
          pageNum: this.currentPage2,
          pageSize: this.PageSize2,
        }).then((res) => {
          this.totalCount2 = res.data.data.total;
          this.tableData4 = res.data.data.data;
          this.tableData4 = this.tableData4.map((obj) => {
            const newObj = {};
            for (let key in obj) {
              newObj[key.toLowerCase()] = obj[key];
            }
            return newObj;
          });
        });
      } else {
        seeData(parms).then((res) => {
          this.tableData4 = res.data.data.list;
          this.tableData4List = res.data.data.titleCN;
          this.tableData4List2 = ["_time"]
          this.totalCount2 = res.data.data.total;
        });
      }
    },
    kseeParms() {
      let sp = JSON.parse(JSON.stringify(this.seeParms));
      const filteredSp = sp.filter(
        (element) => !this.areAllValuesEmptyOrZero(element)
      );
      return filteredSp;
    },

    areAllValuesEmptyOrZero(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (
            value !== null &&
            value !== undefined &&
            value !== "" &&
            value !== false
          ) {
            return false; // 如果找到一个非空值，则返回 false
          }
        }
      }
      return true; // 如果所有属性的值都为空或为0，则返回 true
    },

    // 修改每页显示的条数
    handleSizeChange2(side) {
      // 改变每页显示的条数
      this.PageSize2 = side;
      // 注意：在改变每页显示的条数时，要将页码显示到第一页
      this.currentPage2 = 1;

      let parms = {
        id: this.rowId,
        query: {
          current: this.currentPage2,
          size: side,
        },

        metadataInfoDTO: {
          conditionFields: this.kseeParms(),
        },
      };

      this.MseeData(parms);
    },
    // 显示第几页
    handleCurrentChange2(pageNumber) {
      // 改变默认的页数
      this.currentPage2 = pageNumber;
      let parms = {
        id: this.rowId,
        query: {
          current: this.currentPage2,
          size: this.PageSize2,
        },
        metadataInfoDTO: {
          conditionFields: this.kseeParms(),
        },
      };

      this.MseeData(parms);
    },
    gettype(e) {
      if (e == 1) {
        return this.$t('omit.database');
      }
      if (e == 2) {
        return this.$t('omit.apiinterface');
      }
      if (e == 3) {
        return this.$t('omit.index');
      }
    },
    //编辑
    rowEdit(e, index) {
      this.$router.push({
        path: "/dataObject/add",
        query: {
          type: "edit",
          id: e.id,
          mid: e.modelId,
        },
      });
    },
    rowDel(row) {
      this.DelLsitid = row.id;
      this.dialogVisible3 = true;
    },

    toStr(str) {
      let data;
      if (str.tableComment) {
        data = str.tableComment;
      } else {
        data = str.tableName;
      }
      return decodeURI(data);
    },
    //树
    getdemotree() {
      getDataTree({ object: 0 }).then((res) => {
        if (res.status == 200) {
          this.treeData = res.data.data;
          this.$nextTick(() => {
            let p1 = {}
            if (this.$route.query.pid) {
              this.treeData.forEach(element => {
                if (element.id == this.$route.query.pid) {
                  element.children.forEach(element2 => {
                    if (element2.id == this.$route.query.id) {
                      p1 = element2
                    }

                  });
                }
              });
            } else {
              p1 = this.treeData[0].children[0]
            }
            this.$refs.tree.$refs.commonTree.setCurrentKey(p1.id)



            this.ObjParms.dataObjectTypeId = p1.id;
            this.ObjParms2.modelId = p1.id;
            this.getObjectList(this.ObjParms2);

            var abb = JSON.parse(JSON.stringify(this.treeData));
            var alltree = this.flatten(abb);
            var cc = this.FinTfather(alltree, p1.pid).reverse();
            this.treeWay = "";
            cc.forEach((item) => {
              this.treeWay += item + "/";
            });
            this.treeWay += p1.name;


            // this.getAiModelListFn();
          })
        }
      });
    },

    //数据对象列表
    getObjectList(e) {
      ObjectList(e).then((res) => {
        this.tableData = res.data.data.records;
        this.tableTotal = res.data.data.total;

        this.listAdd.dataObjectTypeId = e.dataObjectTypeId;
      });
    },

    opDialog(e) {
      if (e == 1) {
        this.dialogVisible1 = true;

        if (this.$refs.tree.getCheckedNodes().length == 0) {
          this.treeWay2 = "";
        }
      } else if (e == 2) {
        this.dialogVisible1 = true;
      } else if (e == 3) {
        if (this.$refs.tree.getCheckedKeys().length > 0) {
          this.dialogVisible2 = true;
        } else {
          this.$message({
            message: "请选择要删除的目录",
            type: "warning",
          });
        }
      } else if (e == 4) {
        // if (this.CanEdit) {
        if (this.ObjParms2.modelId) {

          this.$router.push({
            path: "/dataObject/add",
            query: {
              type: "add",
              id: e.id,
              mid: this.ObjParms2.modelId,
            },
          });
        } else {
          this.$message({
            message: "请选择左侧数据",
            type: "warning",
          });
        }

        // }
      } else if (e == 5) {
        // if (this.CanEdit) {
        if (
          this.$refs.multipleTable.selection.length > 0 &&
          this.$refs.multipleTable.selection.length < 2
        ) {
          this.dialogVisible3 = true;
        }
        if (this.$refs.multipleTable.selection.length == 0) {
          this.$message({
            message: this.$t("cip.dc.dataobject.msg.delmsg1"),
            type: "warning",
          });
        }
        if (this.$refs.multipleTable.selection.length > 1) {
          this.$message({
            message: this.$t("cip.dc.dataobject.msg.delmsg2"),
            type: "warning",
          });
        }
      }

      // }
    },

    Addclose() {
      this.addTreeData.parentId = "0";
      this.addTreeData.name = "";
      this.addTreeData.type = "0";
    },

    //点击树节点显示列表
    handleNodeClick(data) {
      this.ObjParms.dataObjectTypeId = data.id;
      this.ObjParms2.modelId = data.id;
      this.getObjectList(this.ObjParms2);

      var abb = JSON.parse(JSON.stringify(this.treeData));
      var alltree = this.flatten(abb);
      var cc = this.FinTfather(alltree, data.pid).reverse();
      this.treeWay = "";
      cc.forEach((item) => {
        this.treeWay += item + "/";
      });
      this.treeWay += data.name;
    },

    //扁平化 树
    flatten(arr) {
      let result = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].children && arr[i].children.length > 0) {
          result = result.concat(this.flatten(arr[i].children));
          arr[i].children = [];
          result = result.concat(arr[i]);
        } else {
          result.push(arr[i]);
        }
      }
      return result;
    },

    //寻找父级
    FinTfather(arr, fid) {
      let arr2 = [];
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].id == fid) {
          if (arr[i].pid != "0") {
            fid = arr[i].pid;
            arr2.push(arr[i].name);
            this.FinTfather(arr, fid);
          } else {
            arr2.push(arr[i].name);
          }
        }
      }
      return arr2;
    },

    //选中树结构
    handleCheckChange(data) {
      var abb = JSON.parse(JSON.stringify(this.treeData));
      var alltree = this.flatten(abb);
      var cc = this.FinTfather(alltree, data.parentId).reverse();
      this.treeWay2 = "";
      cc.forEach((item) => {
        this.treeWay2 += item + "/";
      });
      this.treeWay2 += data.name;
    },
    //树结构单选  储存id
    treeCheck(node, list) {
      if (this.$refs.tree.getCheckedNodes().length > 1) {
        this.$refs.tree.setCheckedKeys([node.id]);
      }
      this.ObjTreeid = node.id;

      this.addTreeData.parentId = node.id;
      if (this.$refs.tree.getCheckedNodes().length == 0) {
        this.addTreeData.parentId = "0";
      }
    },

    //新增树目录
    MAddTree() {
      // if (this.$refs.tree1.getCheckedNodes().length == 0) {
      if (this.$refs.tree.getCheckedNodes().length == 0) {
        this.addTreeData.parentId = undefined;
      }
      if (this.addTreeData.name) {
        if (!this.treeData || this.treeData.length == 0) {
          SaveAddTree(this.addTreeData).then((res) => {
            if (res.status == 200) {
              this.getdemotree();
              this.dialogVisible1 = false;
            }
          });
        }

        //  else if (this.$refs.tree1.getCheckedNodes().length>0) {
        else if (this.$refs.tree.getCheckedNodes().length > 0) {
          //  if(this.$refs.tree1.getCheckedNodes()[0].type != 1){
          if (this.$refs.tree.getCheckedNodes()[0].type != 1) {
            SaveAddTree(this.addTreeData).then((res) => {
              if (res.status == 200) {
                this.getdemotree();
                this.dialogVisible1 = false;
              }
            });
          } else {
            this.$message({
              message: "末级目录下不能新建目录",
              type: "warning",
            });
          }
        }
        // else if(this.$refs.tree1.getCheckedNodes().length==0){
        else if (this.$refs.tree.getCheckedNodes().length == 0) {
          SaveAddTree(this.addTreeData).then((res) => {
            if (res.status == 200) {
              this.getdemotree();
              this.dialogVisible1 = false;
            }
          });
        } else {
          this.$message({
            message: "末级目录下不能新建目录",
            type: "warning",
          });
        }
      } else {
        this.$message({
          message: "请填写目录名称",
          type: "warning",
        });
      }
    },
    //删除树
    Mdeltree() {
      DelObjTree({ id: this.ObjTreeid }).then((res) => {
        if (res.status == 200) {
          this.getdemotree();
          this.dialogVisible2 = false;
        }
      });
    },

    //删除列表数据
    MDelObjectList() {
      DelObjectList({
        // id: this.$refs.multipleTable.selection[0].id
        id: this.DelLsitid,
      }).then((res) => {
        // this.getObjectList(this.ObjParms)
        this.getObjectList(this.ObjParms2);
        this.dialogVisible3 = false;
      });
    },

    //表格分页

    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize;
      this.ObjParms2.query.size = e.pageSize;
      console.log(this.ObjParms2, e);
      this.getObjectList(this.ObjParms2);
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage;
      this.ObjParms2.query.current = e.currentPage;
      this.getObjectList(this.ObjParms2);
    },















    convertToUpperToLower(str) {
      return str.toLowerCase();
    },

    //预览数据对象
    SeeData(e) {
      if (e.sourceType == 2) {
        this.seetype = 2;
        seeUrl.AiUrl = e.apiUrl;
        this.drawer = true;

        getPowerconsumption3({ pageNum: 1, pageSize: 50 }).then((res) => {
          this.tableData4 = res.data.data.data;
          this.totalCount2 = res.data.data.total;
          this.tableData4 = this.tableData4.map((obj) => {
            const newObj = {};
            for (let key in obj) {
              newObj[key.toLowerCase()] = obj[key];
            }
            return newObj;
          });
          dataObjectSF({ id: e.id }).then((res) => {
            this.tableData4List = res.data.data;
            this.tableData4List.forEach((element) => {
              element = this.convertToUpperToLower(element);
            });
          });
        });
      }
      if (e.sourceType == 1) {
        this.seetype = 1;
        this.drawer = true;
        this.rowId = e.id;
        seeData({ id: e.id, query: { current: 1, size: 50 } }).then((res) => {
          this.tableData4 = res.data.data.list;
          this.tableData4List = res.data.data.titleCN;
          this.totalCount2 = res.data.data.total;
        });
      }
      if (e.sourceType == 3) {
        this.seetype = 3;
        this.drawer = true;
        this.rowId = e.id;
        seeData({ id: e.id, query: { current: 1, size: 50 } }).then((res) => {

          this.tableData4 = res.data.data.list;
          this.tableData4List2 = ["_time"]
          this.tableData4List = res.data.data.titleCN;
          this.totalCount4 = res.data.data.total;
        });
      }

    },
    closedrawer() {
      this.Esform.Esnum = 1
      this.Esform.Estype = "1"
      this.Esform.Esx = ""
      this.Esform.Esy = ""
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
      }

      this.isEs = false
      this.currentPage2 = 1
      this.PageSize2 = 50
      this.tableData4 = [];
      this.tableData4List = [];
      this.seeParms = [
        {
          fieldName: "", //字段名称
          queryType: "", //,
          inputValue: "", //输入值,
          startValue: "", //开始值,
          endValue: "", //结束值
        },
      ];
    },



    //关闭新增数据弹框时
    Addclose2() {
      for (let key in this.listAdd) {
        if (key != "dataObjectTypeId") {
          this.listAdd[key] = "";
        }
      }
      this.listAdd.metadataInfoDTO = {
        apiId: "",
        metadataSourceId: "",
        metadataTableId: "",
        saveFields: [],
        conditionFields: [],
        compareFields: [],
      };
    },

    //打开新增时清空数据
    Addopen2() {
      if (this.AddTitle == "新建") {
        this.SBZDbc = [];
        this.SBZDtj = [];
        this.SBZDdb = [];
      }
    },

    //时间类型
    changeTimeType(e, a) {
      if (e == 1) {
        a.gdtime = "";
      }
      if (e == 2) {
        a.DTime = "";
        getDTime({
          dict_code: "dynamic_data_type",
        }).then((res) => {
          this.DtimeType = res.data.data;
        });
      }
    },
    changeQueryType(e, a) {
      a.BCinput = "";
      a.startValue = "";
      a.endValue = "";
    },
  },
};
</script>
<style lang='less' scoped>
.btbox {
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
}

.dialog-footer1 {
  display: flex;
  justify-content: flex-end;
}

::v-deep .avue-crud .el-table {
  height: calc(100vh - 218px) !important;
  max-height: calc(100vh - 218px) !important;
}

.show_table {
  position: relative;
  width: 100%;
  // height: calc(100vh - 160px) !important; 
  overflow: auto;
}

.show_table2 {
  position: relative;
  width: 100%;
  // height: calc(100vh - 210px) !important; 
  overflow: auto;
}

.paging {
  float: right;
  margin-top: 10px;
}
</style>
